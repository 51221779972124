var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          attrs: {
            model: _vm.formData,
            "label-position": "right",
            "label-width": "80px"
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "商品ID:" } },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "请输入" },
                model: {
                  value: _vm.formData.pubProductId,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "pubProductId", _vm._n($$v))
                  },
                  expression: "formData.pubProductId"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "佣金比率:" } },
            [
              _c("el-input-number", {
                attrs: { precision: 2, clearable: "" },
                model: {
                  value: _vm.formData.rate,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "rate", $$v)
                  },
                  expression: "formData.rate"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "计划类型:" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择", clearable: "" },
                  model: {
                    value: _vm.formData.type,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "type", $$v)
                    },
                    expression: "formData.type"
                  }
                },
                _vm._l(_vm.planTypeOptions, function(item, key) {
                  return _c("el-option", {
                    key: key,
                    attrs: { label: item.label, value: item.value }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "定向计划用户:" } },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "请输入" },
                model: {
                  value: _vm.formData.userId,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "userId", _vm._n($$v))
                  },
                  expression: "formData.userId"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.save } },
                [_vm._v("保存")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.back } },
                [_vm._v("返回")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }