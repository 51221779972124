import service from '@/utils/request'; // @Tags PubProductPlan
// @Summary 创建PubProductPlan
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.PubProductPlan true "创建PubProductPlan"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /Plan/createPubProductPlan [post]

export var createPubProductPlan = function createPubProductPlan(data) {
  return service({
    url: "/Plan/createPubProductPlan",
    method: 'post',
    data: data
  });
}; // @Tags PubProductPlan
// @Summary 删除PubProductPlan
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.PubProductPlan true "删除PubProductPlan"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"删除成功"}"
// @Router /Plan/deletePubProductPlan [delete]

export var deletePubProductPlan = function deletePubProductPlan(data) {
  return service({
    url: "/Plan/deletePubProductPlan",
    method: 'delete',
    data: data
  });
}; // @Tags PubProductPlan
// @Summary 删除PubProductPlan
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body request.IdsReq true "批量删除PubProductPlan"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"删除成功"}"
// @Router /Plan/deletePubProductPlan [delete]

export var deletePubProductPlanByIds = function deletePubProductPlanByIds(data) {
  return service({
    url: "/Plan/deletePubProductPlanByIds",
    method: 'delete',
    data: data
  });
}; // @Tags PubProductPlan
// @Summary 更新PubProductPlan
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.PubProductPlan true "更新PubProductPlan"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"更新成功"}"
// @Router /Plan/updatePubProductPlan [put]

export var updatePubProductPlan = function updatePubProductPlan(data) {
  return service({
    url: "/Plan/updatePubProductPlan",
    method: 'put',
    data: data
  });
}; // @Tags PubProductPlan
// @Summary 用id查询PubProductPlan
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.PubProductPlan true "用id查询PubProductPlan"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"查询成功"}"
// @Router /Plan/findPubProductPlan [get]

export var findPubProductPlan = function findPubProductPlan(params) {
  return service({
    url: "/Plan/findPubProductPlan",
    method: 'get',
    params: params
  });
}; // @Tags PubProductPlan
// @Summary 分页获取PubProductPlan列表
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body request.PageInfo true "分页获取PubProductPlan列表"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /Plan/getPubProductPlanList [get]

export var getPubProductPlanList = function getPubProductPlanList(params) {
  return service({
    url: "/Plan/getPubProductPlanList",
    method: 'get',
    params: params
  });
}; // @Tags PubProductPlan
// @Summary 设置商品佣金
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body request.IdsReq true "设置商品佣金"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"设置成功"}"
// @Router /pubProductPlan/setPubProductPlan [post]

export var setPubProductPlan = function setPubProductPlan(data) {
  return service({
    url: "/Plan/setPubProductPlan",
    method: 'post',
    data: data
  });
};